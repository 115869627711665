import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";

export default function ScreenSessionsGroupe() {
  return (
      <Row className="structure">
      <h3 style={{ marginTop: "-2%", marginBottom:"2%"}}>
        {" "}
        Vous avez un trouble du spectre autistique (TSA), un syndrome
        d’Asperger, et vous voulez rencontrer d’autres personnes qui ont le même
        fonctionnement que vous et échanger avec eux ?
      </h3>
        <div className="paragraphe">
          <h1
            style={{ textAlign: "center", marginTop: "5%", marginBottom: "5%" }}
          >
            Bienvenue à <strong>"Bas les Masques"</strong>.{" "}
          </h1>
          <div>
            <h2 style={{ marginBottom: "2%" }}>
              Bas les Masques, c’est un <strong>groupe de parole libre.</strong>
            </h2>
            <ul style={{ margin: "2%" }}>
              <li>
                <p>
                  {" "}
                  On peut y venir une fois, ou plusieurs fois, si on en a envie.
                </p>
              </li>
              <li>
                <p>
                  Il suffit de réserver sa place et de venir le jour J à l’heure
                  convenue.
                </p>
              </li>
              <li>
                <p>
                  On y rencontre 5 autres personnes qui ont aussi un TSA et on
                  discute tous ensemble autour d’une table des difficultés
                  éprouvées au quotidien, de la façon de les masquer, des
                  apprentissages qu’on a du faire au fil des années et des
                  techniques développées pour cacher les atypies de
                  fonctionnement.
                </p>
              </li>
              <li>
                <p>
                  Si vous avez un TSA, pour une fois, dans ce groupe,
                  contrairement à la vie quotidienne, vous n’aurez pas
                  l’impression d’être en décalage, bizarre ou différent(e), mais
                  bien au contraire, d’être enfin à votre place, avec des gens
                  qui vous ressemblent.
                </p>
              </li>
              <li>
                <p>
                  On parle des difficultés socio-relationnelles, des difficultés
                  d’ajustement et d’adaptation, de la « charge mentale de la
                  connexion » aux autres, de la nécessité de s’isoler par
                  moment, de « l’addiction à la connaissance », des
                  hypersensorialités, des rituels, des rigidités, de la
                  difficulté à identifier, comprendre et gérer ses émotions, des
                  difficultés de concentration, de son parcours scolaire, des
                  consultations réalisées, etc. etc.
                </p>
              </li>
              <li>
                <p>
                  En fin de séance, on s’échange les coordonnées, comme ça
                  chacun est libre de se recontacter librement si des affinités
                  se sont créées durant la séance. Une manière de se sentir
                  moins seul(e) dans sa façon de penser et de concevoir la vie,
                  tout simplement.
                </p>
              </li>
            </ul>
            <div style={{ textAlign:"center",marginTop:"5%", marginBottom:"50px" }}>
              <Link to="/PrendreRDVGroupe" className="button">
                Réserver
              </Link>
            </div>
          </div>
        </div>
      </Row>
  );
}
