import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label} from "reactstrap";

const FormAddAppoitment = () => {
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [nbrPlaces, setNbrPlaces] = useState("");
  const [error, setError] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [displayError, setDisplayError] = useState(true);
  const [displayConfirm, setDisplayConfirm] = useState(false);

  var addAppointment = async () => {
    var rawResponse = await fetch(
      "https://backend-cabinetdeneuro.vercel.app/appointment/addAppointment",
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `date=${new Date(date).toUTCString()}&price=${price}&nbrPlaces=${nbrPlaces}`,
      }
    );
    setDate("");
    setPrice("");
    setNbrPlaces("");
    setDisplayConfirm(true);
  };

  useEffect(() => {
    if (error != "") {
      setErrorAlert(<div className="alertError">{error}</div>);
    }
  }, [error]);

  return (
    <div>
      <Form>
        <FormGroup floating>
          <Input
            id="Date/Heure"
            placeholder="Identifiant"
            type="datetime-local"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
          <Label for="name">Date/Heure</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            id="nbrPlaces"
            placeholder="Nombre de places"
            type="number"
            value={nbrPlaces}
            onChange={(e) => setNbrPlaces(e.target.value)}
          />
          <Label for="lastName">Nombre de places</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            id="price"
            placeholder="Prix"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <Label for="lastName">Prix</Label>
        </FormGroup>
      </Form>
      {displayError ? errorAlert : null}
      {displayConfirm ? <div className="alertSuccess">Session  de groupe créée</div> : null}
      <button
        className="button"
        style={{ width: "100%" }}
        onClick={() => {
        setDisplayConfirm(false);
        setDisplayError(true);
          if (date == "") {
            setError("Vous n'avez pas selectionné de date");
          } else if (nbrPlaces == "") {
            setError(
              "Vous n'avez pas renseigné le nombre de places de la séance"
            );
          }  else if (price == "") {
            setError("Vous n'avez pas rennseigné le prix de la séance");
          } else {
            setError("");
            setDisplayError(false);
            addAppointment();
          }
        }}
      >
        Créer la session de groupe
      </button>
    </div>
  );
};

export default FormAddAppoitment;
