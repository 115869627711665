import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";

export default function ScreenPaymentFail() {
  return (
    <Row className="structure">
      <div
        style={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{fontSize:"20px", marginTop:"10%"}}> Echec du paiement</p>
        <Link to="/PrendreRDVGroupe" className="button" style={{width:"30%", textAlign:"center", marginTop:"10%"}}>
          Retourner à la liste des séances disponibles.
        </Link>
      </div>
    </Row>
  );
}
