import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";

export default function ScreenQuiSuisJe() {
  return (
    <Row className="structure">
      <h1> Nathalie GOLOUBOFF, Neuropsychologue à Paris </h1>
      <div className="paragraphe">
        <h2 className="mt-4  mb-3">
          {" "}
          Neuropsychologue spécialisée dans le dépistage du TDAH et du Haut
          Potentiel
        </h2>
        <h3> Le trouble de l'attention avec ou sans hyperactivité (TDA/H)</h3>
        <ul>
          <li>
            Diagnostiquer un TDA/H est une chose. Comprendre son origine en est
            une autre. A quoi peut être lié un TDA/H ? Puis-je vivre avec ou y
            a-t-il des moyens de limiter son impact dans ma vie ? Toutes ses
            questions peuvent trouver réponse en faisant un bilan
            neuropsychologique complet.
          </li>

          <li>
            En cas de problèmes de concentration, le bilan neuropsychologique
            permet de mesurer l'étendue et l'ampleur des difficultés
            attentionnelles et de conseiller pour la poursuite du parcours (que
            mettre en place dans la vie quotidienne ?, quelles démarches
            effectuer ?, vers qui s'orienter ?...)
          </li>

          <li>
            Le TDAH est un trouble neurologique développemental qui peut
            potentiellement amener à :*
            <ul>
              <li>
                des difficultés d’attention et de concentration (fautes
                d'étourderie, dispersion, difficulté à mener ses tâches à terme)
              </li>
              <li>de la fatigue</li>
              <li>de la procrastination</li>
              <li>des oublis</li>
              <li>des difficultés d'organisation/planification/anticipation</li>
              <li>
                des troubles émotionnels (hypersensibilité, difficulté à gérer
                la frustration...)
              </li>
              <li>
                des troubles de la régulation comportementale (hyperactivité,
                impulsivité, difficulté pour respecter les règles et les
                limites…)
              </li>
            </ul>
          </li>
          <li>
            Alors que la personne a un bon potentiel cognitif, le TDAH peut
            avoir un impact fonctionnel sur le plan scolaire (résultats
            irréguliers, pas de retour sur investissement), professionnel
            (difficultés à garder un emploi sur une longue durée, surcharge de
            travail), familial (conflits), social (difficultés relationnelles)
            et psychologique (anxiété, manque de confiance en soi, dépression…),
            d'où l'importance de le diagnostiquer et de le prendre en charge.
          </li>
        </ul>
        <h3> Le haut potentiel / la précocité / la surdouance</h3>
        <ul>
          <li>
            Le bilan neuropsychologique permet de préciser quelles aptitudes
            sont surdéveloppées par comparaison à un fonctionnement normal. En
            effet, le cerveau ne fonctionne pas comme un tout mais il est le
            siège d'un ensemble de compétences. Le bilan permet de déterminer
            quels sont les domaines d'aisance, les aptitudes normalement
            développées, et celles qui peuvent être fragilisées. L'étude des
            contrastes révèlent les spécifités de fonctionnement propres à
            chacun.
          </li>
          <li>
            Dépister un haut potentiel permet de mieux comprendre son propre
            fonctionnement cognitif et émotionnel et d'éviter l'apparition d'un
            trouble psychopathologique secondaire (ou s'il existe déjà, de le
            gérer au mieux). Chez l'enfant, il permet de réfléchir à des
            adaptations (ex : saut de classe, aménagements scolaires)
          </li>
          <li>
            Parfois, le haut potentiel s'associe à un trouble de l'attention ou
            à un trouble spécifique des apprentissages (dyslexie, dyspraxie,
            dysgraphie, TDAH), ce qui crée un équilibre fragile, la personne
            puisant dans ses forces pour compenser ses faiblesses. Quand cet
            équilibre est rompu, les résultats scolaires ou professionnels
            peuvent chuter et des troubles émotionnels peuvent apparaitre
            (anxiété, dépression), d'où l'importance du diagnostic.
          </li>
          <li>
            Etre diagnostiqué, c'est mieux se comprendre et donc mieux
            s'accepter.
          </li>
        </ul>
        <br></br>
        <h2 className="mt-5  mb-3">Expériences professionnelles</h2>
        <ul>
          <li>
            <p><strong>Neuropsychologue et Docteur en Psychologie</strong></p>
            <ul  style={{marginBottom:"10px"}}>
              <li>
                Cabinet libéral depuis 2004 (N° ADELI : 759325483) :
                Neuropsychologue spécialisée dans le dépistage du TDAH et du HPI
                chez l'enfant et chez l'adulte
              </li>
              <li>
                Anciennement rattachée à un établissement médico-éducatif
                (2007-2013) et aux Unités de Neurochirurgie de l'Epilepsie
                (Fondation Rothschild de Paris 19ème, Hôpitaux la Timone et
                Henri Gastaut de Marseille) de 2002-2007
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>
                Enseignante à l'Université (Paris V, Paris XI, Tours){" "}
              </strong>{" "}
              - Master I et Master II de Psychologie (2004- 2015)
            </p>
          </li>
          <li>
              <p>
                <strong>Formatrice professionnelle </strong> pour les personnels
                travaillant en institution spécialisée (2009-2014)
              </p>
            <ul style={{marginBottom:"10px"}}>
              <li>CRMH (Centre de Ressources MultiHandicap)</li>
              <li>
                INSHEA (Institut National Supérieur de formation et de recherche
                pour l'éducation des jeunes Handicapés et les Enseignements
                Adaptés)
              </li>
              <li>
                CESAP (Comité d'Etudes, d'Education et de Soins aux Personnes
                Polyhandicapées)
              </li>
            </ul>
          </li>

          <li>
            <p><strong>Animatrice de groupes de parole pour l'Association
            Epilepsie-France (2007-2009)</strong></p>
          </li>
        </ul>

        <h2 className="mt-5  mb-3">Formation et Diplômes</h2>
        <ul>
          <li>
            <p><strong>
              DESS de Psychologie Clinique et Psychopathologie mention
              Neuropsychologie.{" "}
            </strong>(2003). Université Paris V</p>
          </li>
          <li>
          <p><strong>DEA de Neurosciences. </strong>Université Aix-Marseille I</p>
          </li>
          <li>
          <p><strong>Maîtrise de Psychologie option Neurosciences. </strong>Université Aix-Marseille I</p>
          </li>
          <li>
          <p><strong>Licence de Psychologie. </strong>Université Aix-Marseille I</p>
          </li>
          <li>
          <p><strong>DEUG de Psychologie </strong>Université Paris VIII</p>
          </li>
        </ul>

        <h2 className="mt-5  mb-3">
          Travaux de recherche clinique : Emotions & Epilepsie
        </h2>
        <h3>
          {" "}
          Thèse de Doctorat : « La reconnaissance des émotions faciales :
          Développement chez l'enfant sain et épileptique »{" "}
        </h3>
        <ul>
          <li>
            Réalisée au sein de l'Unité de Neurochirurgie Pédiatrique de
            l'Epilepsie (Fondation Rothschild, Paris 19°) et du Laboratoire de
            Psychologie et Neurosciences Cognitives (CNRS, Université Paris V)
          </li>
          <li>
            Financée par la Ligue Française Contre l'Epilepsie (LFCE) et par la
            Fondation Française pour la Recherche sur l'Epilepsie (FFRE)
          </li>
          <li>Consulter la thèse : <strong><a href="../../theseGolouboff.pdf" style={{color:"#3f8853"}}>Télécharger le pdf</a></strong></li>
          <li>
            Résumé : Cette étude neuropsychologique a permis d'évaluer la
            capacité des enfants sains et épileptiques à reconnaître les
            émotions sur le visage d'autrui, compétence précoce et fondamentale
            dans le développement socio-affectif de l'enfant. Un test de
            reconnaissance des expressions faciales adapté aux enfants a été
            crée et des références normatives ont été établies à ce test. Les
            résultats chez l'enfant et l'adulte sain ont révélé que la capacité
            à reconnaître les émotions dans les expressions faciales est une
            compétence largement fonctionnelle à la pré-adolescence (7-8 ans)
            mais qu'elle s'affine progressivement au cours de l'adolescence et
            jusqu'à l'âge adulte (16-25 ans). Chez le groupe d'enfants
            épileptiques, la performance globale était inférieure à celle des
            sujets témoins et l'épilepsie du lobe temporal s'associait souvent à
            des déficits de reconnaissance de la peur, du dégoût et de la
            neutralité (biais de négativité). En conclusion, cette recherche en
            neuropsychologie a permis de mieux comprendre les troubles
            émotionnels associés aux épilepsies partielles. C'est la première
            recherche à avoir montré que l'épilepsie du lobe temporal peut
            perturber le développement normal de la reconnaissance des émotions
            faciales, en particulier la peur quand les crises d'épilepsie
            débutent au cours des premières années de vie (sous forme de
            convulsions fébriles).
          </li>
        </ul>
        <h3>Publications associées</h3>
        <ul>
          <li>
            Pinabiaux C., Golouboff N., Dubouch C. & Jambaqué I. (2011). Emotion
            contributes to memory for words in children and adolescents:
            Evidences from list learning and delayed recall. Child
            Neuropsychology
          </li>
          <li>
            Jambaqué I., Pinabiaux C., Dubouch C., Fohlen M., Bulteau C. &
            Delalande O. (2009). Verbal emotional memory in children and
            adolescents with temporal epilepsy: a first study. Epilepsy
            Behavior, 16, 69-75.
          </li>
          <li>
            Golouboff N., Fiori N., Delalande O., Fohlen M., Dellatolas G. &
            Jambaqué I. (2008). Impaired facial expression recognition in
            children with temporal lobe epilepsy: impact of early seizure onset
            on fear recognition. Neuropsychologia, 46, 1415-1428.
          </li>
          <li>
            Jambaqué I., Plaza M., Gallagher A., & Golouboff N. (2008).
            Epilepsies de l'enfant : troubles du développement cognitif et
            socio-émotionnel. Edition Solal <a href="https://www.amazon.fr/Epilepsies-lenfant-troubles-d%C3%A9veloppement-socio-%C3%A9motionnel/dp/2353270530" style={{color:"#3f8853"}}><strong>Voir sur Amazon</strong></a>
          </li>
          <li>
            Jambaqué I., Dellatolas G., Fohlen M., Bulteau C., Watier L.,
            Dorfmuller G., Chiron C. & Delalande O. (2007). Memory functions
            following surgery for temporal lobe epilepsy in children.
            Neuropsychologia, 45, 2850-2862
          </li>
        </ul>
      </div>
    </Row>
  );
}
