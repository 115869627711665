import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Row
} from "reactstrap";
import Appointment from "../components/AppointmentClientSide";

export default function ScreenPrendreRDVGroupe() {

  const [seances, setSeances] = useState([]);

  var getAppointments = async () => {
    var rawResult = await fetch(
      "https://backend-cabinetdeneuro.vercel.app/appointment/getAppointments"
    );
    var result = await rawResult.json();
    setSeances(result.appointments);
  };


  var appointmentList = seances.map((seance, i) => {
    return <Appointment seance={seance} />;
  });

  useEffect(() => {
    getAppointments();
    localStorage.setItem("confirmationSent", 0);
  }, []);

  return (
    <Row className="structure">
      <h1>Séances disponibles :</h1>
      <div className="paragraphe">
        <Row>
        {appointmentList}
        </Row>
      </div>
    </Row>
  );
}
