import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import Appointment from "./AppointmentServerSide";

const DisplayAppointments = () => {
  const [seances, setSeances] = useState([]);
  const [refresh, setRefresh] = useState(false);

  var getAppointments = async () => {
    var rawResult = await fetch(
      "https://backend-cabinetdeneuro.vercel.app/appointment/getAppointments"
    );
    var result = await rawResult.json();
    setSeances(result.appointments);
  };


  useEffect(() => {
    getAppointments();
  }, []);

  useEffect(() => {
    getAppointments();
  }, [refresh]);

  var appointmentList = seances.map((seance, i) => {
    return <Appointment seance={seance} />;
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="button"
          style={{ width: "50%" }}
          onClick={() => {
            setRefresh(!refresh);
          }}
        >
          Actualiser les séances
        </button>
      </div>
      <Row>{appointmentList}</Row>
    </div>
  );
};

export default DisplayAppointments;
