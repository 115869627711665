import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import token from "./reducer/token.js";
import screenQuiSuisJe from "./screen/screenQuiSuisJe.js";
import screenLeCabinet from "./screen/screenLeCabinet.js";
import screenPrendreRDV from "./screen/screenPrendreRDV.js";
import screenSessionsGroupe from "./screen/screenSessionsGroupe.js";
import screenLocalisation from "./screen/screenLocalisation.js";
import screenPaymentSuccess from "./screen/screenPaymentSuccess.js";
import screenPaymentFail from "./screen/screenPaymentFail.js";

import screenPrendreRDVGroupe from "./screen/screenPrendreRDVGroupe.js";
import screenBackOffice from "./screen/screenBackOffice.js";
import screenConnexion from "./screen/screenConnexion.js";

import NavBar from "./components/Navbar";
import Header from "./components/Header";

import { Row, Container } from "reactstrap";
import { Helmet } from "react-helmet";

function Content() {
  const location = useLocation();
  return (
    <div style={{ position: "relative" }}>
      <TransitionGroup>
        <CSSTransition timeout={300} classNames="fade" key={location.key}>
          <Switch location={location}>
            <Route component={screenLeCabinet} path="/" exact />
            <Route component={screenQuiSuisJe} path="/QuiSuisJe" exact />
            <Route component={screenPrendreRDV} path="/PrendreRDV" exact />
            <Route
              component={screenSessionsGroupe}
              path="/SessionsGroupe"
              exact
            />
            <Route component={screenLocalisation} path="/Localisation" exact />
            <Route
              component={screenPrendreRDVGroupe}
              path="/PrendreRDVGroupe"
              exact
            />
            <Route component={screenPaymentSuccess} path="/PaymentSuccess" exact />
            <Route component={screenPaymentFail} path="/PaymentFail" exact />
            <Route component={screenConnexion} path="/Connexion" exact />
            <Route component={screenBackOffice} path="/BackOffice" exact />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}
function App() {
  
  const store = createStore(combineReducers({ token }));
  return (
    <Provider store={store}>
      <Router>
        <Helmet>
          <title>Cabinet de neuropsychologie - Paris 12ème</title>
          <meta name="description" content="Cabinet de Nathalie Golouboff, " />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <link rel="icon" type="image/png" href="./icon.png" sizes="16x16" />
        </Helmet>
        <div className="background">
          <Container>
            <Row>
              <Header></Header>
            </Row>
            <div
              style={{
                marginTop: "4%",
                marginLeft: "100px",
              }}
            >
              <NavBar></NavBar>
            </div>
            <Content />
          </Container>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
