import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";

export default function ScreenLeCabinet() {
  return (
      <Row className="structure">
        <h1> Neuropsychologue - Enfants, Adultes - Paris 12ème </h1>
        <div className="paragraphe">
          <h2 className="mt-4  mb-3">
            {" "}
            Nathalie GOLOUBOFF, Neuropsychologue et Docteur en Psychologie à
            Paris, propose une évaluation-diagnostique aux enfants et adultes
            présentant les particularités ou troubles suivants :
          </h2>
          <ul>
            <li>
              <p>
                <strong>Précocité / Haut Potentiel Intellectuel (HPI)/ Surdouance</strong>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Trouble Déficitaire de l'Attention avec ou sans Hyperactivité{" "}
                </strong>
                (TDA/H)
              </p>
            </li>
            <li>
              <p>
                <strong>Trouble du spectre autistique</strong> (autisme,
                syndrome d'Asperger)
              </p>
            </li>
            <li>
              <p>
                <strong>Trouble spécifique d’apprentissage </strong>
                (dyslexie, dyspraxie, dysphasie...)
              </p>
            </li>
            <li>
              <p>
                <strong>Difficultés scolaires</strong>
              </p>
            </li>
            <li>
              <p>
                <strong>Troubles émotionnels et comportementaux </strong>
                (anxiété, dépression, difficultés socio-relationnelles,
                impulsivité...)
              </p>
            </li>
          </ul>
          <h2>
            {" "}
            LE BILAN NEUROPSYCHOLOGIQUE - ENFANTS & ADULTES - test de QI, bilan
            attentionnel pour le TDAH
          </h2>
          <div className="d-flex">
            <p>
              <strong>La neuropsychologie</strong>
              &nbsp; s'adresse à toute personne qui s'interroge sur son
              fonctionnement cérébral.
            </p>
          </div>
          <div className="d-flex">
            <p>
              <strong>Le neuropsychologue</strong>
              &nbsp; analyse, à l'aide d'un entretien clinique et de tests
              standardisés, les particularités intellectuelles, émotionnelles et
              socio-comportementales propres à chacun. Les résultats obtenus
              mettent en lumière les points forts (compétences bien développées,
              voire sur-développées) et les points faibles (fragilités,
              déficits), ce qui peut permettre de répondre à des questions
              telles que : "Suis-je surdoué ou HPI?", "Ai-je un trouble de l'attention
              ?", "Suis-je Asperger ?", "Suis-je dyslexique ?"... L'objectif du
              bilan est de mieux comprendre son mode de fonctionnement, de
              connaître ses points forts et ses points faibles.
            </p>
          </div>
          <strong>
            Le bilan neuropsychologique se déroule en une ou deux séances qui
            comprennent :
          </strong>
          <ul>
            <li>
              <p>
                <strong>Un entretien</strong>
                &nbsp; pour détailler le parcours antérieur et préciser les
                spécificités ou difficultés éprouvées
              </p>
            </li>
            <li>
              <strong>
                La passation de tests psychométriques pour évaluer :
              </strong>
              <ul>
                <li>
                  le QI : tests pour enfants (WPPSI-IV, WISC-V) et pour adultes
                  (WAIS-IV)
                </li>
                <li>l’attention et la concentration</li>
                <li>le langage</li>
                <li>la mémoire</li>
                <li>le repérage dans l'espace</li>
                <li>
                  le fonctionnement exécutif (raisonnement, abstraction,
                  planification, organisation, stratégies...)
                </li>
                <li>le repérage dans le temps</li>
                <li>le profil émotionnel et comportemental</li>
              </ul>
            </li>
            <li>
              <strong>
                Une restitution immédiate des résultats à l'oral avec des
                conseils/recommandations et une orientation vers des
                professionnels spécialisés
              </strong>{" "}
              si besoin
            </li>
            <li>
              <strong>Un compte-rendu écrit détaillé</strong> est adressé par
              mail 2 semaines maximum après la séance
            </li>
          </ul>
          <h3>
            L’objectif du bilan neuropsychologique est de repérer les
            difficultés et les potentialités pour :
          </h3>
          <ul>
            <li>
              Comprendre son fonctionnement général, ses forces, ses faiblesses
            </li>
            <li>Poser un diagnostic</li>
            <li>
              Cibler les prises en charge à mettre en place (orthophonie,
              psychomotricité, ergothérapie, suivi psychologique...)
            </li>
            <li>
              Proposer des aménagements scolaires (projet personnalisé de
              réussite éducative, aide individualisée par une AVS en classe,
              tiers-temps supplémentaire aux examens...)
            </li>
            <li>Effectuer une reconnaissance de handicap (dossier MDPH)</li>
            <li>
              Orienter si besoin vers des médecins spécialisés (pédopsychiatres,
              psychiatres, neuropédiatres, neurologues...)
            </li>
          </ul>
        </div>
      </Row>
  );
}
