import React, { useState } from "react";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Collapse,
  NavItem,
  NavLink,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Navbar color="faded" expand="lg" light >
      <NavbarBrand >
      </NavbarBrand>
      <NavbarToggler
      style={{marginTop:"4%"}}
        onClick={async function noRefCheck() {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} className={"menuDeroulant"} navbar>
        <Nav className="me-auto " navbar>
          <NavItem className="me-3">
            <NavLink
              tag={RRNavLink}
              className={"linkMenu"}
              exact
              to="/"
              onClick={async function noRefCheck() {
                if (isOpen == true) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              Le cabinet
            </NavLink>
          </NavItem>
          <NavItem className="me-3">
            <NavLink
              tag={RRNavLink}
              className={"linkMenu"}
              exact
              to="/QuiSuisJe"
              onClick={async function noRefCheck() {
                if (isOpen == true) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              Qui-suis-je
            </NavLink>
          </NavItem>
          <NavItem className="me-3">
            <NavLink
              tag={RRNavLink}
              className={"linkMenu"}
              exact
              to="/PrendreRDV"
              onClick={async function noRefCheck() {
                if (isOpen == true) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              Prendre RDV
            </NavLink>
          </NavItem>
          <NavItem className="me-3">
            <NavLink
              tag={RRNavLink}
              className={"linkMenu"}
              exact
              to="/SessionsGroupe"
              onClick={async function noRefCheck() {
                if (isOpen == true) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              Bas les masques
            </NavLink>
          </NavItem>

          <NavItem className="me-3">
            <NavLink
              tag={RRNavLink}
              className={"linkMenu"}
              exact
              to="/Localisation"
              onClick={async function noRefCheck() {
                if (isOpen == true) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              Localisation
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
