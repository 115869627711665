import React, { useState } from "react";
import { Modal, Col } from "reactstrap";

const AppointmentServerSide = (props) => {
    const [visible, setVisible] = useState(false);

    var deleteAppointment = async (id) => {
        await fetch(`https://backend-cabinetdeneuro.vercel.app/appointment/deleteAppointment/${id}`, {
          method: "DELETE",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: ``,
        });
      };

  return (
    <Col sm="4" md="3">
      <div
        onClick={() => setVisible(!visible)}
        className="cards"
        style={{
          border: props.seance.nbrPlaces != 0 ? "#9b9b9b solid 2px" : "red solid 2px",
        }}
      >
        <h1>{new Date(props.seance.date).toLocaleDateString("fr")}</h1>
        <h1>
          {new Date(props.seance.date).getHours()}h à{" "}
          {new Date(props.seance.date).getHours() + 2}h
        </h1>
        <h2>{props.seance.price}€</h2>
        <h3>Groupe {props.seance.gender}</h3>
        <h3 style={{ color: props.seance.nbrPlaces != 0 ? "#315499" : "red" }}>
          Places : {props.seance.nbrPlaces}
        </h3>
      </div>
      <Modal
        centered
        toggle={() => setVisible(!visible)}
        isOpen={visible}
        size="xl"
        onCancel={() => setVisible(!visible)}
      >
        <table style={{ margin: "5%" }}>
          <thead>
            <tr style={{ color: "#315499" }}>
              <td>Prénom</td>
              <td>Nom</td>
              <td>Téléphone</td>
              <td>Email</td>
            </tr>
          </thead>
          <tbody>
            {props.seance.attendees.map((attendee, i) => (
              <tr>
                <td>{attendee.firstname}</td>
                <td>{attendee.lastname}</td>
                <td>{attendee.phone}</td>
                <td>{attendee.mail}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%",
          }}
        >
          <button
            className="button"
            style={{ width: "50%" }}
            onClick={() => {
              deleteAppointment(props.seance._id);
              setVisible(!visible);
            }}
          >
            Supprimer cette séance
          </button>
        </div>
      </Modal>
    </Col>
  );
};

export default AppointmentServerSide;