import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";

export default function ScreenLocalisation() {
  return (
    <Row className="structure">
      <h1> Accès au cabinet</h1>
      <div className="paragraphe">
        <div
          style={{
            display: "flex",
            margin: "5%",
            justifyContent: "space-around",
          }}
        >
          <div>
            <h2 style={{ textAlign: "center" }}>Métro</h2>
            <p>- RER A : Station Nation</p>
            <p>- Métro 1, 2, 6, 9 : Station Nation</p>
            <p>- Métro 8 : Station Montgallet</p>
          </div>
          <div>
            <div>
              <h2 style={{ textAlign: "center" }}>Bus</h2>
              <p>- Bus 26, 56, 57, 86, 351 : arrêt Nation</p>
              <p>- Bus 29 : arrêt Fabre d’Eglantine</p>
              <p>- Bus 46 : arrêt Montgallet</p>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "5%",
            width: "100%",
            height: "100%",
            padding: "3%",
          }}
        >
          <iframe
            title="Localisation du cabinet"
            width="100%"
            height="500"
            scrolling="no"
            src="https://maps.google.fr/maps?q=4+bis+rue+fabre+d'eglantine+75012+paris&amp;ie=UTF8&amp;hq=&amp;hnear=4+Bis+Rue+Fabre+d'%C3%89glantine,+75012+Paris&amp;gl=fr&amp;t=m&amp;z=14&amp;ll=48.846172,2.395611&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </Row>
  );
}
